import React, { useState } from "react";
import { FormSection, reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";
import AddressForm from "../address/AddressForm";
import { InputBox, RadioButton, DropdownSelect } from "../../../inputs";
import Alert from "../../../alert";
import { Checkbox } from "../../../inputs";
import FieldErrorLabel from "../../components/FieldErrorLabel";
import { validateInstitution } from "./InstitutionValidation";
import { ALERT_TYPES } from "../../../types";

export const InstitutionForm = (props) => {
  const { country, error, disableFields, institutionTypes, change } = props;
  const restricted = props.initialValues?.restricted || false;
  const admin = localStorage.getItem("isAdmin");
  const [disableIsForProfit, setDisableIsForProfit] = useState(false);
  const handleIsForProfitOnChange = (e, newValue) => {
    let selectedType = institutionTypes.find(
      (type) => type.name === newValue,
    ).isForProfit;
    if (selectedType != null) {
      selectedType = selectedType.toString();
      setDisableIsForProfit(true);
    } else {
      setDisableIsForProfit(false);
    }
    change("isForProfit", selectedType);
  };

  return (
    <form>
      <Alert alertType={ALERT_TYPES.ERROR} message={error} />
      {admin && restricted ? (
          <div style={{ marginTop: "30px" }} className='p-15'>
          <label style={{ color: "#ef0000" }}>
          Restricted
          </label>
        </div>
        ) : (
          null
        )}

      <div className='form-group'>
        <Field
          component={DropdownSelect}
          id='institutionType'
          name='institutionType'
          label='Institution Type'
          placeholder='Institution Type'
          options={institutionTypes.map(({ name }) => ({
            item: name,
            value: name,
          }))}
          onChange={handleIsForProfitOnChange}
          required
          disabled={disableFields}
        />
      </div>

      <div className='form-group'>
        <label htmlFor='institutionStatus'>
          Institution Status <b style={{ color: "#ef0000" }}>*</b>
        </label>
        <div
          className='row align-items-center justify-content-center'
          role='radiogroup'
          id='institutionStatus'
        >
          <div className='col-sm-4'>
            <Field
              component={RadioButton}
              label='For profit institution'
              value='true'
              type='radio'
              name='isForProfit'
              ariaLabel='For profit institution required field'
              disabled={disableFields || disableIsForProfit}
            />
          </div>
          <div className='col-sm-4'>
            <Field
              component={RadioButton}
              label='Not for profit institution'
              value='false'
              type='radio'
              name='isForProfit'
              ariaLabel='Not for profit institution required field'
              disabled={disableFields || disableIsForProfit}
            />
          </div>
          <FieldErrorLabel
            name='isForProfit'
            className='institution-type-error'
          />
        </div>
      </div>
      <div className='form-group'>
        <Field
          component={InputBox}
          id='name'
          name='name'
          label='Institution Name'
          ariaLabel='Institution name required field'
          placeholder='Institution Name'
          required
          disabled={disableFields}
        />
      </div>
      <FormSection name='address'>
        <AddressForm disableFields={disableFields} selectedCountry={country} />
      </FormSection>
    </form>
  );
};

InstitutionForm.propTypes = {
  country: PropTypes.string,
  error: PropTypes.string,
  disableFields: PropTypes.bool,
  institutionTypes: PropTypes.array,
  change: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "institutionForm",
  validate: validateInstitution,
})(InstitutionForm);
