import React from "react";

function HeaderBanner(props) {
  return (
    <>
      <div
        className='alert alert-danger mb-0'
        style={{ padding: "1rem 3rem 0 4rem" }}
      >
        <h3>
          <i class='bi bi-exclamation-circle mr-1'></i>Attention
        </h3>
        <p>
          Because of a lapse in government funding, the information on this
          website may not be up to date, transactions submitted via the website
          may not be processed, and the agency may not be able to respond to
          inquiries until appropriations are enacted. The NIH Clinical Center
          (the research hospital of NIH) is open. For more details about its
          operating status, please visit{" "}
          <a href={"https://cc.nih.gov"} target={"_blank"}>
            cc.nih.gov
          </a>
          . Updates regarding government operating status and resumption of
          normal operations can be found at{" "}
          <a href={"https://opm.gov"} target={"_blank"}>
            opm.gov
          </a>
          .
        </p>
        <hr />
        <h3>
          <i class='bi bi-exclamation-circle mr-1'></i>Atenci&#x00F3;n
        </h3>
        <p>
          Ante la falta de fondos del gobierno federal, no se actualizará este
          sitio web y la organización no responderá a transacciones ni consultas
          hasta que se aprueben los fondos. El Centro Clínico de los Institutos
          Nacionales de la Salud (el hospital de investigación) permanecerá
          abierto. Consulte{" "}
          <a href={"https://cc.nih.gov"} target={"_blank"}>
            cc.nih.gov
          </a>{" "}
          (en inglés). Infórmese sobre el funcionamiento del gobierno federal y
          el reinicio de las actividades en{" "}
          <a href={"https://opm.gov"} target={"_blank"}>
            opm.gov
          </a>
          .
        </p>
      </div>
    </>
  );
}

export default HeaderBanner;
